export const v1_product_not_found: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg xmlns="http://www.w3.org/2000/svg" width="216" height="137" viewBox="0 0 216 137" fill="none">
        <path
            opacity="0.1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46 97H137C137.515 97 138.017 96.9444 138.5 96.8389C138.983 96.9444 139.485 97 140 97H192C195.866 97 199 93.866 199 90C199 86.134 195.866 83 192 83H186C182.134 83 179 79.866 179 76C179 72.134 182.134 69 186 69H205C208.866 69 212 65.866 212 62C212 58.134 208.866 55 205 55H183C186.866 55 190 51.866 190 48C190 44.134 186.866 41 183 41H119C122.866 41 126 37.866 126 34C126 30.134 122.866 27 119 27H62C58.134 27 55 30.134 55 34C55 37.866 58.134 41 62 41H22C18.134 41 15 44.134 15 48C15 51.866 18.134 55 22 55H47C50.866 55 54 58.134 54 62C54 65.866 50.866 69 47 69H7C3.13401 69 0 72.134 0 76C0 79.866 3.13401 83 7 83H46C42.134 83 39 86.134 39 90C39 93.866 42.134 97 46 97ZM209 97C212.866 97 216 93.866 216 90C216 86.134 212.866 83 209 83C205.134 83 202 86.134 202 90C202 93.866 205.134 97 209 97Z"
            fill="#FD6A6A"
        />
        <path
            d="M17 111H38.4541M4.12793 111H11M207.128 111H210M180 111H202.428"
            stroke="#FD6A6A"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M81 6.30273L92.0996 18.7559M133.1 6.30273L122 18.7559L133.1 6.30273ZM107 2V18.7559V2Z"
            stroke="#FD6A6A"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M132.498 42.8098L138.773 47.5164H75.5801L82.6159 42.8259L83.0635 42.5275L82.7332 42.1029L76.8199 34.5H137.47L132.382 42.1325L132.119 42.526L132.498 42.8098Z"
            fill="#FFF4ED"
            stroke="#FD6A6A"
        />
        <rect x="72.5" y="46.5" width="70" height="74" rx="1.5" fill="white" stroke="#FD6A6A" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76.585 87.0061V52.6453C76.585 51.2297 77.7452 50.082 79.1764 50.082L140.153 114.164C140.153 116.052 138.642 117.582 136.778 117.582H79.9599C78.096 117.582 76.585 116.052 76.585 114.164V101.395V97.9301V87.0061ZM76.585 94.4248V90.5765V94.4248Z"
            fill="#FFF4ED"
        />
        <path
            d="M73 87.4631V49.8853C73 48.3371 74.2705 47.082 75.8377 47.082H140.761C141.782 47.082 142.609 47.9187 142.609 48.9509V117.164C142.609 119.228 140.954 120.902 138.913 120.902H76.6956C74.6546 120.902 73 119.228 73 117.164V103.199V99.4099M73 95.5764V91.3678"
            stroke="#FD6A6A"
            strokeWidth="2.5"
            strokeLinecap="round"
        />
        <path
            d="M74.8636 47.082V35.8689C74.8636 34.8368 75.6145 34.0001 76.5408 34.0001L138.596 34.0001C139.523 34.0001 140.273 34.8368 140.273 35.8689V47.082"
            stroke="#FD6A6A"
            strokeWidth="2.5"
        />
        <path
            d="M92.1504 66.705C93.9566 66.705 95.4209 65.2408 95.4209 63.4346C95.4209 61.6283 93.9566 60.1641 92.1504 60.1641C90.3441 60.1641 88.8799 61.6283 88.8799 63.4346C88.8799 65.2408 90.3441 66.705 92.1504 66.705Z"
            fill="#E8F0FE"
            stroke="#FD6A6A"
            strokeWidth="2.5"
            strokeLinecap="round"
        />
        <path
            d="M122.986 66.705C124.793 66.705 126.257 65.2408 126.257 63.4346C126.257 61.6283 124.793 60.1641 122.986 60.1641C121.18 60.1641 119.716 61.6283 119.716 63.4346C119.716 65.2408 121.18 66.705 122.986 66.705Z"
            fill="#E8F0FE"
            stroke="#FD6A6A"
            strokeWidth="2.5"
            strokeLinecap="round"
        />
        <path
            d="M122.519 66.7045C122.519 74.9616 115.825 81.6553 107.568 81.6553C99.3109 81.6553 92.6172 74.9616 92.6172 66.7045"
            stroke="#FD6A6A"
            strokeWidth="2.5"
            strokeLinecap="round"
        />
        <path
            d="M75.8547 34.9316L82.4331 41.4253C82.8262 41.8133 82.8303 42.4464 82.4423 42.8395C82.3752 42.9075 82.2986 42.9656 82.215 43.0118L74.8633 47.0821"
            stroke="#FD6A6A"
            strokeWidth="2.5"
            strokeLinecap="round"
        />
        <path
            d="M139.44 34.999L133.145 41.4195C132.758 41.8138 132.764 42.447 133.159 42.8336C133.225 42.8982 133.299 42.9533 133.38 42.9975L140.867 47.0818"
            stroke="#FD6A6A"
            strokeWidth="2.5"
            strokeLinecap="round"
        />
        <path
            d="M158.38 20L159.812 24.4083H164.447L160.697 27.1328L162.13 31.5411L158.38 28.8166L154.63 31.5411L156.062 27.1328L152.312 24.4083H156.947L158.38 20Z"
            fill="#FD6A6A"
        />
        <path
            d="M179.536 85.793L180.379 88.3861H183.105L180.899 89.9887L181.742 92.5818L179.536 90.9792L177.33 92.5818L178.173 89.9887L175.967 88.3861H178.693L179.536 85.793Z"
            fill="#FD6A6A"
        />
        <path
            d="M63.536 128.793L64.3786 131.386H67.1051L64.8993 132.989L65.7418 135.582L63.536 133.979L61.3302 135.582L62.1727 132.989L59.9669 131.386H62.6934L63.536 128.793Z"
            fill="#FD6A6A"
        />
    </svg>
);

export const v1_empty_notify: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg xmlns="http://www.w3.org/2000/svg" width="240" height="194" viewBox="0 0 240 194" fill="none">
        <path
            d="M161.033 81.7801C160.082 81.3649 159.037 81.1629 158.027 80.8972C156.743 80.5568 155.391 80.3997 154.175 79.9021C151.745 78.9032 149.832 77.1374 148.073 75.2332C143.674 70.4633 140.346 64.9677 137.606 59.1353C135.274 54.1672 132.365 49.6741 128.423 45.9442C128.364 46.0078 128.304 46.0714 128.244 46.1313C125.904 48.4695 122.658 49.921 119.087 49.921C115.516 49.921 112.27 48.4695 109.931 46.1313C107.744 43.9502 106.337 40.9798 106.157 37.6914L81.5664 48.7987L140.743 103.621C146.302 101.234 151.861 98.8507 157.394 96.4115C160.333 95.1171 162.706 93.1082 164.125 90.1452C165.779 86.6884 164.548 83.3102 161.037 81.7801H161.033Z"
            fill="#FDCC50"
        />
        <path
            d="M81.0537 49.0288C77.6733 53.5892 75.7492 58.7931 75.1727 64.5656C74.5999 70.3268 75.4684 75.916 77.5348 81.292C79.1782 85.568 80.9451 89.7955 82.1093 94.2361C83.1762 98.3139 84.0222 102.422 83.8313 106.668C83.704 109.432 83.0864 112.018 81.3868 114.318C80.7242 115.212 80.2825 116.335 79.9755 117.423C78.9723 120.977 80.6194 123.843 84.172 124.805C85.2014 125.081 86.2795 125.175 87.3352 125.355C89.6075 125.235 91.7525 124.719 93.8151 123.828C108.239 117.614 122.662 111.404 137.086 105.19C138.303 104.666 139.523 104.143 140.743 103.619L81.5665 48.7969L81.0537 49.0288Z"
            fill="#FFAA01"
        />
        <path
            d="M98.7007 35.8507C98.7007 35.8507 98.6895 35.8245 98.682 35.8095C96.2487 30.1979 88.7992 28.6678 84.3183 32.8428C81.2711 35.6823 80.6871 40.0033 82.2968 43.258L98.7007 35.8507Z"
            fill="#FFAA01"
        />
        <path
            d="M136.973 111.283C131.14 113.849 125.297 116.397 119.461 118.952C117.376 119.865 117.368 119.895 117.795 122.102C118.899 127.807 123.691 131.814 129.52 132.001C130.598 131.814 131.702 131.724 132.75 131.425C137.036 130.194 139.896 127.392 141.068 123.123C142.277 118.712 141.188 114.773 137.976 111.458C137.654 111.126 137.388 111.103 136.977 111.286L136.973 111.283Z"
            fill="#0e7673"
        />
        <path
            d="M119.774 46C125.577 46 130.281 41.299 130.281 35.5C130.281 29.701 125.577 25 119.774 25C113.972 25 109.268 29.701 109.268 35.5C109.268 41.299 113.972 46 119.774 46Z"
            fill="#FD6A6A"
        />
        <path
            d="M114.943 35.0634C114.943 33.148 115.311 31.639 116.046 30.5365C116.792 29.4341 117.989 28.8828 119.637 28.8828C121.285 28.8828 122.477 29.4341 123.212 30.5365C123.958 31.639 124.331 33.148 124.331 35.0634C124.331 37.0011 123.958 38.5212 123.212 39.6237C122.477 40.7262 121.285 41.2774 119.637 41.2774C117.989 41.2774 116.792 40.7262 116.046 39.6237C115.311 38.5212 114.943 37.0011 114.943 35.0634ZM121.525 35.0634C121.525 33.9387 121.402 33.0756 121.157 32.4742C120.912 31.8617 120.406 31.5555 119.637 31.5555C118.869 31.5555 118.362 31.8617 118.117 32.4742C117.872 33.0756 117.75 33.9387 117.75 35.0634C117.75 35.8207 117.794 36.4499 117.883 36.951C117.972 37.441 118.151 37.8419 118.418 38.1537C118.696 38.4544 119.103 38.6047 119.637 38.6047C120.172 38.6047 120.573 38.4544 120.84 38.1537C121.118 37.8419 121.302 37.441 121.391 36.951C121.48 36.4499 121.525 35.8207 121.525 35.0634Z"
            fill="white"
        />
        <path
            d="M239.961 188.48C240.267 190.767 238.664 192.889 236.354 193.172C234.067 193.478 231.945 191.875 231.663 189.565C231.356 187.278 232.959 185.156 235.27 184.873C237.556 184.567 239.678 186.17 239.961 188.48Z"
            fill="#0e7673"
        />
        <path
            d="M162.142 131.949C162.283 133.104 161.482 134.141 160.35 134.306C159.195 134.447 158.158 133.646 157.993 132.514C157.851 131.359 158.653 130.322 159.784 130.157C160.94 130.015 161.977 130.817 162.142 131.949Z"
            fill="#455A64"
        />
        <path
            d="M112.255 1.81276C112.397 2.96794 111.595 4.00525 110.464 4.17028C109.308 4.31173 108.271 3.51017 108.106 2.37856C107.965 1.22338 108.766 0.186069 109.898 0.0210421C111.029 -0.143984 112.09 0.681149 112.255 1.81276Z"
            fill="#263238"
        />
        <path
            d="M232.019 90.761C232.16 91.9162 231.359 92.9535 230.227 93.1185C229.072 93.26 228.035 92.4584 227.87 91.3268C227.728 90.1716 228.53 89.1343 229.661 88.9693C230.817 88.8279 231.854 89.6294 232.019 90.761Z"
            fill="#263238"
        />
        <path
            d="M17.4603 186.738C17.6018 187.893 16.8002 188.93 15.6686 189.095C14.5134 189.237 13.4761 188.435 13.3111 187.303C13.1697 186.148 13.9712 185.111 15.1028 184.946C16.258 184.804 17.2953 185.606 17.4603 186.738Z"
            fill="#0e7673"
        />
        <path
            d="M8.33601 102.172C8.64249 104.459 7.03937 106.58 4.729 106.863C2.4422 107.17 0.32044 105.567 0.037537 103.256C-0.268941 100.97 1.33417 98.8478 3.64454 98.5649C5.93134 98.2584 8.05311 99.8615 8.33601 102.172Z"
            fill="#0e7673"
        />
        <path
            d="M52.209 31.33L57.6549 34.3948C57.6549 34.3948 56.4997 29.9862 61.309 25.6484C66.1184 21.3106 71.7293 23.6917 74.4876 17.9865L68.7352 14.2852C68.7352 14.2852 66.7785 19.566 61.5684 21.1691C57.6313 22.395 53.2699 24.1867 52.209 31.33Z"
            fill="#0e7673"
        />
        <path
            d="M86.4407 144.936L87.2894 138.736C87.2894 138.736 83.6353 141.447 77.8122 138.547C72.0127 135.671 72.1541 129.565 65.836 129.117L64.4922 135.813C64.4922 135.813 70.1267 135.695 73.5451 139.938C76.1148 143.168 79.3917 146.539 86.4407 144.889V144.936Z"
            fill="#0e7673"
        />
        <path
            d="M35.4953 67.1172L28.2812 69.1682C28.2812 69.1682 30.073 78.6455 37.4992 77.0659C37.4992 77.0659 32.902 74.2605 35.4953 67.1172Z"
            fill="#455A64"
        />
        <path
            d="M44.4781 83.951L47.6372 77.2792C47.6372 77.2792 43.7709 72.7528 37.5234 77.0671C37.5234 77.0671 44.6431 77.6329 44.5017 83.951H44.4781Z"
            fill="#455A64"
        />
        <path
            d="M184.561 66.5962C184.254 64.3094 185.858 62.1876 188.168 61.9047C190.455 61.5982 192.577 63.2014 192.859 65.5117C193.166 67.7985 191.563 69.9203 189.252 70.2032C186.966 70.5097 184.844 68.9066 184.561 66.5962Z"
            fill="#0e7673"
        />
        <path
            d="M206.39 116.484L205.541 122.684C205.541 122.684 209.195 119.973 215.018 122.873C220.818 125.749 220.676 131.855 226.994 132.303L228.338 125.607C228.338 125.607 222.704 125.725 219.309 121.482C216.716 118.252 213.462 114.881 206.413 116.531L206.39 116.484Z"
            fill="#455A64"
        />
        <path
            d="M197.951 33.6864L204.529 37.2699C204.529 37.2699 209.857 29.2307 203.444 25.1758C203.444 25.1758 204.765 30.3859 197.928 33.6864H197.951Z"
            fill="#0e7673"
        />
        <path
            d="M203.279 15.3673L196.347 17.937C196.347 17.937 195.947 23.878 203.444 25.151C203.444 25.151 198.729 19.7759 203.279 15.3438V15.3673Z"
            fill="#0e7673"
        />
    </svg>
);

export const v1_empty_cart: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg width="246" height="106" viewBox="0 0 246 106" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M73.116 73.8127H145.992C146.405 73.8127 146.807 73.7682 147.194 73.6837C147.581 73.7682 147.983 73.8127 148.395 73.8127H190.039C193.135 73.8127 195.644 71.3028 195.644 68.2068C195.644 65.1108 193.135 62.6009 190.039 62.6009H185.234C182.137 62.6009 179.628 60.0911 179.628 56.9951C179.628 53.899 182.137 51.3892 185.234 51.3892H200.449C203.546 51.3892 206.055 48.8793 206.055 45.7833C206.055 42.6873 203.546 40.1774 200.449 40.1774H182.831C185.927 40.1774 188.437 37.6676 188.437 34.5715C188.437 31.4755 185.927 28.9657 182.831 28.9657H131.577C134.673 28.9657 137.183 26.4558 137.183 23.3598C137.183 20.2637 134.673 17.7539 131.577 17.7539H85.9294C82.8334 17.7539 80.3235 20.2637 80.3235 23.3598C80.3235 26.4558 82.8334 28.9657 85.9294 28.9657H53.8958C50.7998 28.9657 48.2899 31.4755 48.2899 34.5715C48.2899 37.6676 50.7998 40.1774 53.8958 40.1774H73.9168C77.0129 40.1774 79.5227 42.6873 79.5227 45.7833C79.5227 48.8793 77.0129 51.3892 73.9168 51.3892H41.8832C38.7872 51.3892 36.2773 53.899 36.2773 56.9951C36.2773 60.0911 38.7872 62.6009 41.8832 62.6009H73.116C70.0199 62.6009 67.5101 65.1108 67.5101 68.2068C67.5101 71.3028 70.0199 73.8127 73.116 73.8127ZM203.648 73.8145C206.744 73.8145 209.254 71.3047 209.254 68.2086C209.254 65.1126 206.744 62.6028 203.648 62.6028C200.552 62.6028 198.042 65.1126 198.042 68.2086C198.042 71.3047 200.552 73.8145 203.648 73.8145Z"
            fill="#FFD023"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M202.211 73.8125H229.84H202.211Z" fill="#0BB783" />
        <path
            d="M26.7712 73.8125H59.0609M1 73.8125H14.7586M239.25 73.8125H245M202.211 73.8125H229.84"
            stroke="#FD6A6A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M102.721 9.81859L99.5642 16.8596M83.3126 5.98428L89.0139 20.8939M72.7326 22.3192L78.5336 24.9015"
            stroke="#FD6A6A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M97.072 97.5469L97.5775 99.1027H99.2135L97.89 100.064L98.3955 101.62L97.072 100.659L95.7485 101.62L96.254 100.064L94.9305 99.1027H96.5665L97.072 97.5469Z"
            fill="#FD6A6A"
        />
        <path
            d="M129.722 8.23047L131.154 12.6388H135.789L132.039 15.3633L133.471 19.7716L129.722 17.0471L125.972 19.7716L127.404 15.3633L123.654 12.6388H128.289L129.722 8.23047Z"
            fill="#FD6A6A"
        />
        <path
            d="M172.878 66.0234L173.72 68.6166H176.447L174.241 70.2192L175.084 72.8123L172.878 71.2097L170.672 72.8123L171.515 70.2192L169.309 68.6166H172.035L172.878 66.0234Z"
            fill="#FD6A6A"
        />
        <mask id="mask0_1947_2824" maskUnits="userSpaceOnUse" x="85" y="21" width="85" height="85">
            <rect x="85" y="21" width="85" height="85" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1947_2824)">
            <path
                d="M109.791 98.9154C107.843 98.9154 106.176 98.2218 104.788 96.8346C103.401 95.4475 102.708 93.7799 102.708 91.832C102.708 89.8841 103.401 88.2166 104.788 86.8294C106.176 85.4423 107.843 84.7487 109.791 84.7487C111.739 84.7487 113.406 85.4423 114.794 86.8294C116.181 88.2166 116.874 89.8841 116.874 91.832C116.874 93.7799 116.181 95.4475 114.794 96.8346C113.406 98.2218 111.739 98.9154 109.791 98.9154ZM145.208 98.9154C143.26 98.9154 141.592 98.2218 140.205 96.8346C138.818 95.4475 138.124 93.7799 138.124 91.832C138.124 89.8841 138.818 88.2166 140.205 86.8294C141.592 85.4423 143.26 84.7487 145.208 84.7487C147.156 84.7487 148.823 85.4423 150.21 86.8294C151.597 88.2166 152.291 89.8841 152.291 91.832C152.291 93.7799 151.597 95.4475 150.21 96.8346C148.823 98.2218 147.156 98.9154 145.208 98.9154ZM103.416 35.1654H155.656C157.013 35.1654 158.046 35.7704 158.755 36.9805C159.463 38.1905 159.492 39.4154 158.843 40.6549L146.27 63.3216C145.621 64.5022 144.75 65.4171 143.658 66.0664C142.566 66.7157 141.371 67.0404 140.072 67.0404H113.687L109.791 74.1237H148.749C149.753 74.1237 150.594 74.4631 151.273 75.1419C151.952 75.8207 152.291 76.6619 152.291 77.6654C152.291 78.6688 151.952 79.51 151.273 80.1888C150.594 80.8676 149.753 81.207 148.749 81.207H109.791C107.135 81.207 105.128 80.0412 103.77 77.7096C102.413 75.378 102.354 73.0612 103.593 70.7591L108.374 62.082L95.6243 35.1654H92.0827C91.0792 35.1654 90.2381 34.826 89.5592 34.1471C88.8804 33.4683 88.541 32.6272 88.541 31.6237C88.541 30.6202 88.8804 29.7791 89.5592 29.1003C90.2381 28.4214 91.0792 28.082 92.0827 28.082H97.8379C98.4872 28.082 99.107 28.2591 99.6973 28.6133C100.288 28.9674 100.73 29.4692 101.025 30.1185L103.416 35.1654Z"
                fill="#FD6A6A"
            />
        </g>
        <circle cx="153" cy="38" r="12" fill="#323B49" />
        <path
            d="M153.006 44.1932C152.109 44.1894 151.342 43.9527 150.705 43.483C150.069 43.0133 149.582 42.3295 149.245 41.4318C148.908 40.5341 148.739 39.4527 148.739 38.1875C148.739 36.9261 148.908 35.8485 149.245 34.9545C149.586 34.0606 150.075 33.3788 150.711 32.9091C151.351 32.4394 152.116 32.2045 153.006 32.2045C153.897 32.2045 154.66 32.4413 155.296 32.9148C155.933 33.3845 156.419 34.0663 156.756 34.9602C157.097 35.8504 157.268 36.9261 157.268 38.1875C157.268 39.4564 157.099 40.5398 156.762 41.4375C156.425 42.3314 155.938 43.0152 155.302 43.4886C154.665 43.9583 153.9 44.1932 153.006 44.1932ZM153.006 42.6761C153.794 42.6761 154.41 42.2917 154.853 41.5227C155.3 40.7538 155.523 39.642 155.523 38.1875C155.523 37.2216 155.421 36.4053 155.217 35.7386C155.016 35.0682 154.726 34.5606 154.347 34.2159C153.972 33.8674 153.525 33.6932 153.006 33.6932C152.222 33.6932 151.607 34.0795 151.16 34.8523C150.713 35.625 150.487 36.7367 150.484 38.1875C150.484 39.1572 150.584 39.9773 150.785 40.6477C150.989 41.3144 151.279 41.8201 151.654 42.1648C152.029 42.5057 152.48 42.6761 153.006 42.6761Z"
            fill="white"
        />
    </svg>
);

//Special Offer
export const v1_sp_bg_1: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg width="201" height="78" viewBox="0 0 201 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6C0 2.68629 2.68629 0 6 0H46.0189C46.2742 3.35622 49.0784 6 52.5 6C55.9216 6 58.7257 3.35622 58.981 0H195C198.314 0 201 2.68629 201 6V72C201 75.3137 198.314 78 195 78H58.9811C58.7258 74.6438 55.9216 72 52.5 72C49.0784 72 46.2743 74.6438 46.019 78H6C2.68629 78 0 75.3137 0 72V6Z"
            fill="url(#paint0_linear_42_609)"
        />
        <defs>
            <linearGradient id="paint0_linear_42_609" x1="11.3309" y1="-10.1529" x2="34.3883" y2="104.172" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFFEF9" />
                <stop offset="1" stop-color="#C7F3CA" />
            </linearGradient>
        </defs>
    </svg>
);
export const v1_sp_bg_2: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg width="201" height="78" viewBox="0 0 201 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6C0 2.68629 2.68629 0 6 0H46.0189C46.2742 3.3562 49.0784 6 52.5 6C55.9216 6 58.7258 3.3562 58.9811 0H195C198.314 0 201 2.68629 201 6V72C201 75.3137 198.314 78 195 78H58.9811C58.7258 74.6438 55.9216 72 52.5 72C49.0784 72 46.2743 74.6438 46.019 78H6C2.68629 78 0 75.3137 0 72V6Z"
            fill="url(#paint0_linear_42_607)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_42_607"
                x1="18.8438"
                y1="-7.67293e-07"
                x2="32.8224"
                y2="88.6693"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FFF0F0" />
                <stop offset="1" stop-color="#FFD9D9" />
            </linearGradient>
        </defs>
    </svg>
);
export const v1_sp_bg_3: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg width="201" height="78" viewBox="0 0 201 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6C0 2.68629 2.68629 0 6 0H46.0189C46.2742 3.35622 49.0784 6 52.5 6C55.9216 6 58.7257 3.35622 58.981 0H195C198.314 0 201 2.68629 201 6V72C201 75.3137 198.314 78 195 78H58.9811C58.7258 74.6438 55.9216 72 52.5 72C49.0784 72 46.2743 74.6438 46.019 78H6C2.68629 78 0 75.3137 0 72V6Z"
            fill="url(#paint0_linear_47_12349)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_47_12349"
                x1="-5.91177"
                y1="-12.0868"
                x2="12.3819"
                y2="93.2966"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#D0BFFF" stop-opacity="0" />
                <stop offset="1" stop-color="#DACCFF" />
            </linearGradient>
        </defs>
    </svg>
);
export const v1_sp_bg_4: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg width="201" height="78" viewBox="0 0 201 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6C0 2.68629 2.68629 0 6 0H46.0189C46.2742 3.35622 49.0784 6 52.5 6C55.9216 6 58.7257 3.35622 58.981 0H195C198.314 0 201 2.68629 201 6V72C201 75.3137 198.314 78 195 78H58.9811C58.7258 74.6438 55.9216 72 52.5 72C49.0784 72 46.2743 74.6438 46.019 78H6C2.68629 78 0 75.3137 0 72V6Z"
            fill="url(#paint0_linear_42_1690)"
        />
        <defs>
            <linearGradient id="paint0_linear_42_1690" x1="3.49999" y1="-16.5" x2="18.8134" y2="93.0661" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFBC8C" stop-opacity="0" />
                <stop offset="1" stop-color="#FFCEAA" />
            </linearGradient>
        </defs>
    </svg>
);
export const v1_sp_bg_5: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg width="201" height="78" viewBox="0 0 201 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6C0 2.68629 2.68629 0 6 0H46.0189C46.2742 3.35622 49.0784 6 52.5 6C55.9216 6 58.7257 3.35622 58.981 0H195C198.314 0 201 2.68629 201 6V72C201 75.3137 198.314 78 195 78H58.9811C58.7258 74.6438 55.9216 72 52.5 72C49.0784 72 46.2743 74.6438 46.019 78H6C2.68629 78 0 75.3137 0 72V6Z"
            fill="url(#paint0_linear_42_619)"
        />
        <defs>
            <linearGradient id="paint0_linear_42_619" x1="44" y1="-18" x2="41.1646" y2="75.1749" gradientUnits="userSpaceOnUse">
                <stop stop-color="#7EB900" stop-opacity="0" />
                <stop offset="1" stop-color="#D7F29E" />
            </linearGradient>
        </defs>
    </svg>
);
export const v1_sp_bg_6: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg width="201" height="78" viewBox="0 0 201 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6C0 2.68629 2.68629 0 6 0H46.0189C46.2742 3.35622 49.0784 6 52.5 6C55.9216 6 58.7257 3.35622 58.981 0H195C198.314 0 201 2.68629 201 6V72C201 75.3137 198.314 78 195 78H58.9811C58.7258 74.6438 55.9216 72 52.5 72C49.0784 72 46.2743 74.6438 46.019 78H6C2.68629 78 0 75.3137 0 72V6Z"
            fill="url(#paint0_linear_42_1721)"
        />
        <defs>
            <linearGradient id="paint0_linear_42_1721" x1="260.61" y1="118.934" x2="224.098" y2="-67.2349" gradientUnits="userSpaceOnUse">
                <stop offset="0.192108" stop-color="#91FFF9" />
                <stop offset="1" stop-color="#1DA099" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export const v1_google: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <g>
            <path
                d="M24.2663 12.2763C24.2663 11.4605 24.2001 10.6404 24.059 9.83789H12.7402V14.4589H19.222C18.953 15.9492 18.0888 17.2676 16.8233 18.1054V21.1037H20.6903C22.9611 19.0137 24.2663 15.9272 24.2663 12.2763Z"
                fill="#4285F4"
            />
            <path
                d="M12.7401 24.0008C15.9766 24.0008 18.7059 22.9382 20.6945 21.1039L16.8276 18.1055C15.7517 18.8375 14.3627 19.252 12.7445 19.252C9.61388 19.252 6.95946 17.1399 6.00705 14.3003H2.0166V17.3912C4.05371 21.4434 8.2029 24.0008 12.7401 24.0008Z"
                fill="#34A853"
            />
            <path
                d="M6.00277 14.3002C5.50011 12.8099 5.50011 11.196 6.00277 9.70569V6.61475H2.01674C0.314734 10.0055 0.314734 14.0004 2.01674 17.3912L6.00277 14.3002Z"
                fill="#FBBC04"
            />
            <path
                d="M12.7401 4.74966C14.4509 4.7232 16.1044 5.36697 17.3434 6.54867L20.7695 3.12262C18.6001 1.0855 15.7208 -0.034466 12.7401 0.000808666C8.2029 0.000808666 4.05371 2.55822 2.0166 6.61481L6.00264 9.70575C6.95064 6.86173 9.60947 4.74966 12.7401 4.74966Z"
                fill="#EA4335"
            />
        </g>
        <defs>
            <clipPath id="clip0_6560_2381">
                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
);
export const v1_facebook: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <g>
            <path
                d="M24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 17.9895 4.8882 22.954 10.625 23.8542V15.4688H7.57812V12H10.625V9.35625C10.625 6.34875 12.4166 4.6875 15.1576 4.6875C16.4701 4.6875 17.8438 4.92188 17.8438 4.92188V7.875H16.3306C14.84 7.875 14.375 8.80008 14.375 9.75V12H17.7031L17.1711 15.4688H14.375V23.8542C20.1118 22.954 24.5 17.9895 24.5 12Z"
                fill="#1877F2"
            />
            <path
                d="M17.1711 15.4688L17.7031 12H14.375V9.75C14.375 8.80102 14.84 7.875 16.3306 7.875H17.8438V4.92188C17.8438 4.92188 16.4705 4.6875 15.1576 4.6875C12.4166 4.6875 10.625 6.34875 10.625 9.35625V12H7.57812V15.4688H10.625V23.8542C11.8674 24.0486 13.1326 24.0486 14.375 23.8542V15.4688H17.1711Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_6560_753">
                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
);
export const v1_apple: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path
            d="M21.3428 17.1447C21.0101 17.9133 20.6163 18.6208 20.16 19.2713C19.5381 20.158 19.0288 20.7719 18.6364 21.1127C18.028 21.6722 17.3762 21.9587 16.6782 21.975C16.1771 21.975 15.5728 21.8324 14.8693 21.5432C14.1636 21.2553 13.515 21.1127 12.922 21.1127C12.3 21.1127 11.633 21.2553 10.9195 21.5432C10.2049 21.8324 9.62928 21.9832 9.18916 21.9981C8.51981 22.0266 7.85264 21.7319 7.18668 21.1127C6.76164 20.742 6.22999 20.1064 5.59309 19.2061C4.90976 18.2446 4.34796 17.1297 3.90784 15.8587C3.43648 14.4857 3.2002 13.1563 3.2002 11.8692C3.2002 10.3948 3.51878 9.12321 4.15689 8.0576C4.6584 7.20166 5.32557 6.52647 6.16059 6.03081C6.99562 5.53514 7.89786 5.28256 8.86949 5.2664C9.40114 5.2664 10.0983 5.43085 10.9647 5.75405C11.8287 6.07834 12.3834 6.24279 12.6266 6.24279C12.8085 6.24279 13.4247 6.0505 14.4694 5.66714C15.4573 5.31162 16.2911 5.16441 16.9742 5.2224C18.8251 5.37178 20.2157 6.10142 21.1405 7.41595C19.4851 8.41896 18.6662 9.82379 18.6825 11.626C18.6975 13.0297 19.2067 14.1979 20.2076 15.1254C20.6611 15.5558 21.1676 15.8885 21.7312 16.1248C21.609 16.4793 21.48 16.8188 21.3428 17.1447ZM17.0978 0.440125C17.0978 1.54038 16.6958 2.56768 15.8946 3.51854C14.9277 4.64892 13.7582 5.30211 12.49 5.19904C12.4738 5.06705 12.4645 4.92812 12.4645 4.78214C12.4645 3.7259 12.9243 2.59552 13.7408 1.67127C14.1485 1.20331 14.667 0.814209 15.2957 0.503814C15.9231 0.19805 16.5166 0.0289566 17.0747 0C17.091 0.147086 17.0978 0.294182 17.0978 0.440111V0.440125Z"
            fill="black"
        />
    </svg>
);
export const v1_twitter: React.ReactElement<React.SVGProps<SVGSVGElement>> = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4457 23L10.8963 15.0901L3.94911 23H1.01001L9.59233 13.2311L1.01001 1H8.55596L13.7862 8.45502L20.3395 1H23.2786L15.0946 10.3165L23.9917 23H16.4457ZM19.7187 20.77H17.74L5.21836 3.23H7.19735L12.2124 10.2532L13.0796 11.4719L19.7187 20.77Z"
            fill="#242E36"
        />
    </svg>
);
